import React, { useContext, useState } from "react";
import classes from "./Language.module.css";
import LspDet from "./LspDet";
import Breadcum from "../../Common/Breadcum";
import profl from "./../../../icons/profl.jpg";
import { AuthContext } from "../../../context/AuthContext";
import { FaUserEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import api from "../../Common/api";
import { useQuery } from "@tanstack/react-query";
import DealerDet from "./DealerDet";
const Profile = () => {
  const { user, token, setUser, setToken } = useContext(AuthContext);
  console.log("user", user);
  const [existingData, setExistingData] = useState(null);
  const [dealerData, setDealerData] = useState(null);
  const navigate = useNavigate();
  const HandleRoute = () => {
    navigate("/verify-user", {
      state: { user },
    });
  };
  const fetchData = async () => {
    const response = await api.get(
      `admin/edit-lender-service-provider/${user?.lsp_id}`
    );
    return response.data;
  };
  const DetailsResponse = useQuery({
    queryKey: ["bankDetailsList", user],
    queryFn: () => fetchData(),
    enabled: !!user?.lsp_id,
    onSuccess: (data) => {
      setExistingData(data?.data);
    },
  });
  console.log("existingData", existingData);
  const fetchDealerData = async () => {
    const response = await api.get(`edit-dealer/${user?.dealer_id}`);
    return response.data;
  };
  const DetailsDealerResponse = useQuery({
    queryKey: ["bankDetailsList", user],
    queryFn: () => fetchDealerData(),
    enabled: !!user?.dealer_id,
    onSuccess: (data) => {
      setDealerData(data?.data);
    },
  });
  console.log("existingData", existingData);
  console.log("dealerData", dealerData);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Profile" />
        {/* <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            </div> */}
        <div
          className={classes.tableContai}
          style={{ padding: "15px", background: "#fff" }}
        >
          <div
            style={{
              textAlign: "left",
              background: "#fff",
              padding: "20px",
              border: "3px solid #878a99",
              borderRadius: "8px",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <img src={profl} className={classes.imgofprop} />
              {/* <FaUserEdit
                size={22}
                style={{ paddingRight: "10px", color: "#878a99" }}
              /> */}
              <button
                style={{ width: "120px", maxHeight: "30px" }}
                className={classes.addbtn}
                type="button"
                onClick={HandleRoute}
              >
                Change Password
              </button>
            </div>
            <div className={classes.fourtxtcon} style={{ marginTop: "20px" }}>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Name</p>
                <p className={classes.pbtfrtxt}>{user?.name || ""}</p>
              </div>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Email</p>
                <p className={classes.pbtfrtxt}>{user?.email || ""}</p>
              </div>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Mobile No</p>
                <p className={classes.pbtfrtxt}>{user?.mobile || ""}</p>
              </div>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Department</p>
                <p className={classes.pbtfrtxt}>{user?.department || ""}</p>
              </div>
            </div>
            <div className={classes.fourtxtcon} style={{ marginTop: "15px" }}>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Role</p>
                <p className={classes.pbtfrtxt}>{user?.role || ""}</p>
              </div>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>User Type</p>
                <p className={classes.pbtfrtxt}>{user?.user_type || ""}</p>
              </div>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Alternative Mobile No</p>
                <p className={classes.pbtfrtxt}>
                  {user?.alternative_mobile_1 || ""}
                </p>
              </div>
              <div className={classes.actcontfrtxt}>
                <p className={classes.ptpfrtxt}>Updated By</p>
                <p className={classes.pbtfrtxt}>{user?.updated_by || ""}</p>
              </div>
            </div>
            {user?.lsp_id && <LspDet defaultValue={existingData || []} />}
            {user?.dealer_id && <DealerDet existingData={dealerData || []} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
