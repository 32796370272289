import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import DealerForm from "./DealerForm";

const EditGuarantorDocuments = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(2);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const location = useLocation();
  const { val } = location.state || "";

  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Edit Guarantor Documents" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            <DealerForm defaultValue={val} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGuarantorDocuments;
