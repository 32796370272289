import React from "react";
import { useForm } from "react-hook-form";
import styles from "./Search.module.css";
import { useToast } from "../../../context/ToastProvder";
const SearchComp = ({ onSearch }) => {
  const { register, handleSubmit, reset, watch } = useForm();
  // const onSubmit = (data) => {
  //   console.log("searchForm submitted:", data);
  //   onSearch(data);
  // };
  const { showToast } = useToast();
  const onSubmit = (data) => {
    if (data.pan && data.pan.length !== 10) {
      showToast("Invalid PAN Number.");
      return;
    }
    if (data.aadhar && data.aadhar.length !== 12) {
      showToast("Invalid Aadhar Number.");
      return;
    }
    console.log("searchForm submitted:", data);
    onSearch(data);
  };

  const resetHandler = () => {
    reset();
    onSearch(null);
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: "10px" }}>
        <div className={styles.fourinputasc} style={{ gap: "20px" }}>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Name
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("name")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Mobile
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("mobile")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Alternative Mobile Number
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("alternative_number")}
            />
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Email
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("email")}
            />
          </div>
        </div>
        <div
          className={styles.threeinputads}
          style={{ gap: "20px", marginTop: "10px" }}
        >
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              PAN
            </label>
            <input
              type="text"
              className={styles.inpttsc}
              {...register("pan")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              Aadhar
            </label>
            <input
              type="number"
              className={styles.inpttsc}
              {...register("aadhar")}
            />
          </div>

          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#000000",
                fontWeight: "bold",
              }}
            >
              GST Number
            </label>

            <input
              type="text"
              className={styles.inpttsc}
              {...register("gst")}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            display: "flex",
            gap: "10px",
            marginBottom: "10px",
            position: "relative",
            bottom: "5px",
          }}
        >
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#45cb85",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="submit"
          >
            Search
          </button>
          <button
            style={{
              maxHeight: "30px",
              border: "none",
              color: "#fff",
              background: "#f06548",
              borderRadius: "5px",
              position: "relative",
              top: "14px",
              height: "25px",
            }}
            type="button"
            onClick={resetHandler}
          >
            Clear
          </button>
        </div>
      </form>
    </div>
  );
};

export default SearchComp;
