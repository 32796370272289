import React, { useState } from "react";
import profl from "./../../../icons/profl.jpg";
import styles from "./SeprateDet.module.css";
import { Backdrop } from "@mui/material";
import Modal from "./Modal";
const BorrowerDetails = ({ Detailsb, borrowerId }) => {
  const [rowToEdit, setRowToEdit] = useState(null);
  const [add, setAdd] = useState(false);
  console.log("Detailsb", Detailsb);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  console.log("Detailsb in brwdd", Detailsb);
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <Modal
          addHandle={addHandle}
          doc={Detailsb?.documents || {}}
          type="borrower"
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div style={{ textAlign: "right" }}>
        <button
          style={{
            padding: "5px 7px",
            color: "#fff",
            background: "#f06548",
            border: "none",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "11px",
          }}
          onClick={addHandle}
        >
          View Document
        </button>
      </div>
      <div className={styles.brwdettcon}>
        <img
          src={
            Detailsb?.documents?.borrower_avtar
              ? `https://insta.finayo.tech${Detailsb.documents.borrower_avtar}`
              : profl
          }
          style={{ width: "100px", borderRadius: "5px", height: "100px" }}
        />
        <div className={styles.wholetxtcon}>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Borrower Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Borrower ID</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.loan_details?.borrower_id || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Created On</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_details?.created_at || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Mobile No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.mobile || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Date Of Birth</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.dob_date || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Marital Status</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.marital_status || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Spouse's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.spouse_name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Father's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.father_name || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Gender</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.gender || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Alternate Number</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Email Id</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.email || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Pan No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.pan || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Aadhar No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.aadhar || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Driving License No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_details?.driving_license_no || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>State</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.address_details?.full_address
                  ?.state || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Country</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.address_details?.full_address
                  ?.country || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Cibil</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.credit_details?.borrower_cibil_score || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Postal</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.address_details?.full_address
                  ?.pc || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Loan Amount</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_details?.loan_amount || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Down Payment</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_details?.downpayment || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Loan ID</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.loan_details?.loan_id || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>No of Earning Members</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.earning_members || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Family Members</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.family_members || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Family Income</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.family_income || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Resident type</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.resident_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Years Living In Resident</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_address?.years_living || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>City</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.address_details?.city || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.current_address || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Occupation</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.current_occupation || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Expected Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_occupation?.expected_monthly_earning || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Extra Source Income</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.extra_source_income || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.monthly_earning || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.occupation_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Total Income</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.borrower_occupation?.total_income || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Source Income Area</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.source_income_area || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Vehicle Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_occupation?.vehicle_type || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Permanent Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.borrower_address?.permanent_address || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BorrowerDetails;
