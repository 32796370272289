import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { useLocation, useNavigate } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { MdElectricRickshaw, MdOutlineNavigateNext } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import scooty from "../../../icons/motorcycle.png";
import rickshaw from "../../../icons/rickshaw.png";
import battery from "../../../icons/battery.png";
import auto from "../../../icons/tuk-tuk.png";
const ApplyLoan = () => {
  const [type, setType] = useState(1);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Apply Loan" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div className={classes.tablco}>
            <div className={classes.totalcheckconnn}>
              <div
                className={classes.applylnx}
                onClick={() => handleEditRow("L3")}
              >
                <p className={classes.applyluptxt}>E-Reckshaw (L3)</p>
                <div className={classes.applylnlrdv}>
                  <p className={classes.applylnlrdvtxt}>Apply Loan</p>
                  <div>
                    {/* <MdElectricRickshaw size="40px" /> */}
                    <img src={auto} style={{ width: "40px" }} />
                  </div>
                </div>
              </div>
              <div
                className={classes.applylnx}
                onClick={() => handleEditRow("L5")}
              >
                <p className={classes.applyluptxt}>E-Auto (L5)</p>
                <div className={classes.applylnlrdv}>
                  <p className={classes.applylnlrdvtxt}>Apply Loan</p>
                  <div>
                    <img src={rickshaw} style={{ width: "45px" }} />
                  </div>
                </div>
              </div>
              <div
                className={classes.applylnx}
                onClick={() => handleEditRow("L2")}
              >
                <p className={classes.applyluptxt}>E-Two Wheeler</p>
                <div className={classes.applylnlrdv}>
                  <p className={classes.applylnlrdvtxt}>Apply Loan</p>
                  <div>
                    <img src={scooty} style={{ width: "45px" }} />
                  </div>
                </div>
              </div>
              <div
                className={classes.applylnx}
                onClick={() => handleEditRow("Bat")}
              >
                <p className={classes.applyluptxt}>Battery</p>
                <div className={classes.applylnlrdv}>
                  <p className={classes.applylnlrdvtxt}>Apply Loan</p>
                  <div>
                    <img src={battery} style={{ width: "40px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyLoan;
