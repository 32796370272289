import React, { useState } from "react";
import profl from "./../../../icons/profl.jpg";
import styles from "./SeprateDet.module.css";
import { Backdrop } from "@mui/material";
import GuaModal from "./GuaModal";
import { useNavigate } from "react-router-dom";
import { BiSolidEdit } from "react-icons/bi";
const GuarantorDetails = ({ Detailsb, borrowerId }) => {
  console.log("Detailsb", Detailsb);

  const [rowToEdit, setRowToEdit] = useState(null);
  const [add, setAdd] = useState(false);
  console.log("Detailsb", Detailsb);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const navigate = useNavigate();
  const EditHandle = () => {
    navigate("/edit-guarantor-documents", {
      state: { borrowerId },
    });
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <GuaModal
          addHandle={addHandle}
          doc={Detailsb?.documents || {}}
          type="guarantor"
          borrowerId={borrowerId}
        />
      </Backdrop>
      <div style={{ textAlign: "right" }}>
        {" "}
        <button
          style={{
            padding: "5px 7px",
            color: "#fff",
            background: "#f06548",
            border: "none",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "11px",
            height: "24px",
            position: "relative",
            top: "3px",
          }}
          onClick={EditHandle}
        >
          <BiSolidEdit
            size="14"
            // style={{ position: "relative", top: "3px" }}
          />{" "}
          <span style={{ position: "relative", bottom: "3px" }}>
            {" "}
            Edit Guarantor Document
          </span>
        </button>
        <button
          style={{
            padding: "5px 7px",
            color: "#fff",
            background: "#f06548",
            border: "none",
            margin: "8px",
            borderRadius: "4px",
            fontSize: "11px",
          }}
          onClick={addHandle}
        >
          View Document
        </button>
      </div>
      <div className={styles.brwdettcon}>
        <div className={styles.wholetxtcon}>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Guarantor Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.name || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Mobile No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Date Of Birth</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.dob_date || ""}
              </p>
            </div>
            {/* <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Marital Status</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.marital_status || ""}
              </p>
            </div> */}
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Father's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.father_name || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            {/* <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Spouse's Name</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.spouse_name || ""}
              </p>
            </div> */}

            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Gender</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.gender || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Alternate Number</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.mobile || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Email Id</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.email || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Pan No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.pan || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Aadhar No</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_details?.aadhar || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>City</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.city || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>State</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.state || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Country</p>
              <p className={styles.pbtfrtxt}>
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.country || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Cibil</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.credit_details?.guarantor_cibil_score}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Postal</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.pc || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Occupation</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.current_occupation || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Monthly Earning</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.monthly_earning || ""}
              </p>
            </div>
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.occupation_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Total Income</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.total_income || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Vehicle Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.vehicle_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Single Line Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details
                  ?.single_line_address || ""}
              </p>
            </div>
            {/* <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Occupation Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.occupation_type || ""}
              </p>
            </div> */}
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>District</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.address_details?.full_address
                  ?.dist || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Resident Type</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.resident_type || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Current Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.current_address || ""}
              </p>
            </div>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Years Living At</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.years_living || ""}
              </p>
            </div>{" "}
          </div>
          <div className={styles.fourtxtcon}>
            <div className={styles.actcontfrtxt}>
              <p className={styles.ptpfrtxt}>Permanent Address</p>
              <p className={styles.pbtfrtxt}>
                {" "}
                {Detailsb?.guarantor_occupation?.permanent_address || ""}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuarantorDetails;
