import React, { useContext, useState } from "react";
import styles from "./Login.module.css";
import logo from "./../../icons/finayo_logo.png";
import { FaKey, FaUser } from "react-icons/fa";
import { Backdrop } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Modal from "./Modal";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../context/AuthContext";
import useCustomMutation from "../CustomHook/useCustomMutation";
import { useToast } from "../../context/ToastProvder";
const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();
  const { showToast } = useToast();
  const [add, setAdd] = useState(false);
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const { setUser, setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  // const handleSignIn = () => {
  //   navigate("/b2cAdmin/dashboard");
  // };
  //test@example.us
  //uAT- ReactTest@123
  //LIve - admin@finayo2025
  // testadmin1@gmail.com
  //   12345678
  //deepaktesla@yopmail.com pw -00000000
  const onSubmit = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };

    updateVpnMutation.mutate(payload, {
      onSuccess: (response) => {
        if (response.status === "success") {
          const token = response.data.token; // Assuming the token is in response.data.token
          console.log("token on fetch", token);
          setToken(response.data.token);
          // setUser(response.data.user);
          // Store the token in localStorage
          // localStorage.setItem("authToken", token);

          // Update the authentication context
          // setAuth({ token, isAuthenticated: true });

          // Redirect to the dashboard
          navigate("/dashboard");
        } else {
          console.log("Login failed", response.message);
        }
      },
      onError: (error) => {
        console.error("Login error", error.response.data);
        const { message, data: errorDetails } = error.response.data;
        // console.error("Login data", error.response.data);
        showToast(message, "error");
      },
    });
  };

  const updateVpnMutation = useCustomMutation({
    url: "admin/login",
    method: "post",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.logincontainer}>
      <div>
        <img src={logo} className={styles.loginLogo} />
      </div>
      <div className={styles.signMain}>
        <div className={styles.inputcontainer}>
          <div className={styles.iconcontainer}>
            <FaUser style={{ color: "#12468a" }} />
          </div>

          <input
            {...register("email", { required: true })}
            className={styles.logininput}
            placeholder="Enter Username"
          />
        </div>
        <div className={styles.inputcontainer}>
          <div className={styles.iconcontainer}>
            {/* <FaKey style={{ color: "#12468a" }} /> */}
            <i
              className="fa fa-key icon"
              aria-hidden="true"
              style={{ color: "#12468a" }}
            ></i>
          </div>

          <input
            type="password"
            {...register("password", { required: true })}
            className={styles.logininput}
            placeholder="Enter Password"
          />
        </div>
        <div className={styles.downloadwrapper}>
          <button
            type="submit"
            // onClick={handleSignIn}
            className={styles.btn}
          >
            Sign In
          </button>
        </div>
        <h5
          style={{
            float: "right",
            margin: "10px",
            fontSize: "16px",
            color: "#12468a",
            cursor: "pointer",
          }}
          className={styles.forgotpw}
          onClick={addHandle}
        >
          Forgot Password ?
        </h5>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <Modal addHandle={addHandle} />
      </Backdrop>
    </form>
  );
};

export default LoginPage;
