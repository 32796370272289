import { createBrowserRouter } from "react-router-dom";
import React, { useState } from "react";
import LoginPage from "../components/Login/Login";
import SiteLayout from "../components/Dashboard/SiteLayout";
import MainDashboad from "../components/Dashboard/MainDashboad/MainDashboad";
import RoleAccess from "../components/Dashboard/RoleAccess/RoleAccess";
import AccessPermisson from "../components/Dashboard/AccessPermisson/AccessPermisson";
import Language from "../components/Dashboard/Language/Language";
import DealersList from "../components/Dashboard/DealersList/DealersList";
import ProductType from "../components/Dashboard/ProductType/ProductType";
import ProductModalAsset from "../components/Dashboard/ProductModalAsset/ProductModalAsset";
import BatteryTypes from "../components/Dashboard/BatteryTypes/BatteryTypes";
import Manufacturer from "../components/Dashboard/Manufacturer/Manufacturer";
import ResidentType from "../components/Dashboard/ResidentType/ResidentType";
import MaritalStatus from "../components/Dashboard/MaritalStatus/MaritalStatus";
import Gender from "../components/Dashboard/Gender/Gender";
import OccupationTypes from "../components/Dashboard/OccupationTypes/OccupationTypes";
import VehicleTypes from "../components/Dashboard/VehicleTypes/VehicleTypes";
import Relations from "../components/Dashboard/Relations/Relations";
import Lenders from "../components/Dashboard/Lenders/Lenders";
import LenderConsentContent from "../components/Dashboard/LenderConsentContent/LenderConsentContent";
import EmiDebitConsent from "../components/Dashboard/EmiDebitConsent/EmiDebitConsent";
import CreditDetailsConsent from "../components/Dashboard/CreditDetailsConsent/CreditDetailsConsent";
import OnboardingScreenContent from "../components/Dashboard/OnboardingScreenContent/OnboardingScreenContent";
import BorrowerList from "../components/Dashboard/Borrower/BorrowerList";
import AddBorrower from "../components/Dashboard/AddBorrower/AddBorrower";
import ViewBorrowerDetails from "../components/Dashboard/ViewBorrowerDetails/ViewBorrowerDetails";
import LoanApllication from "../components/Dashboard/Loan Application/LoanApllication";
import ViewLoanApp from "../components/Dashboard/ViewLoanApp/ViewLoanApp";
import AddLoanApplication from "../components/Dashboard/AddLoanApplication/AddLoanApplication";
import Lspwallet from "../components/Dashboard/Lsp Wallet/Lspwallet";
import LspSetting from "../components/Dashboard/LspSetting/LspSetting";
import Lspborrower from "../components/Dashboard/Lsp Borrower/Lspborrower";
import ExecutiveBorrower from "../components/Dashboard/Executive Borrower/ExecutiveBorrower";
import LspLoan from "../components/Dashboard/LspLoan/LspLoan";
import CreateDealer from "../components/Dashboard/CreateDealer/CreateDealer";
import AddProduct from "../components/Dashboard/AddProduct/AddProduct";
import User from "../components/Dashboard/User/User";
import UserType from "../components/Dashboard/UserType/UserType";
import DepartmentL from "../components/Dashboard/DepartmentL/DepartmentL";
import Profile from "../components/Dashboard/Profile/Profile";
import ExcelProduct from "../components/Dashboard/ExcelProduct/ExcelProduct";
import DealerAuthority from "../components/Dashboard/DealerAuthority/DealerAuthority";
import AddDealerAuthority from "../components/Dashboard/AddDealerAuthority/AddDealerAuthority";
import ViewDeDetails from "../components/Dashboard/ViewDeDetails/ViewDeDetails";
import AddLender from "../components/Dashboard/AddLender/AddLender";
import LenderAuthority from "../components/Dashboard/LenderAuthority/LenderAuthority";
import LenderServiceProvider from "../components/Dashboard/LenderServiceProvider/LenderServiceProvider";
import AddLenderServiceProvider from "../components/Dashboard/AddLenderServiceProvider/AddLenderServiceProvider";
import LenderServiceProviderAuthority from "../components/Dashboard/LenderServiceProviderAuthority/LenderServiceProviderAuthority";
import LenderExacutive from "../components/Dashboard/LenderExacutive/LenderExacutive";
import AddLenderEx from "../components/Dashboard/AddLenderEx/AddLenderEx";
import EditDealerD from "../components/Dashboard/EditDealerD/EditDealerD";
import VarifyItsYou from "../components/Dashboard/VarifyItsYou/VarifyItsYou";
import ChangePW from "../components/Dashboard/ChangePW/ChangePW";
import ViewLspDet from "../components/Dashboard/ViewLspDet/ViewLspDet";
import ApplyLoan from "../components/Dashboard/ApplyLoan/ApplyLoan";
import StepTwo from "../components/Dashboard/StepTwo/StepTwo";
import VerifyAadhar from "../components/Dashboard/VerifyAadhar/VerifyAadhar";
import EditGenDocuments from "../components/Dashboard/EditGenDocuments/EditGenDocuments";
import EditGuarantorDocuments from "../components/Dashboard/EditGuarantorDocuments/EditGuarantorDocuments";
import EditCoAppDocuments from "../components/Dashboard/EditCoAppDocuments/EditCoAppDocuments";
import EditBorrowerDocuments from "../components/Dashboard/EditBorrowerDocuments/EditBorrowerDocuments";

const routes = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />,
  },
  // {
  //   path: "/b2cAdmin/login",
  //   element: <LoginPage />,
  // },
  {
    path: "",
    // path: "/b2cAdmin",
    element: <SiteLayout />,
    children: [
      //   { index: true, element: <Home /> },
      // { path: "*", element: <ErrorPage /> },
      // <Suspense fallback={<div>Loading...</div>}></Suspense>,
      //   <Suspense
      //     fallback={
      //       <DotLoading
      //         contStyle={{
      //           textAlign: "center",
      //           display: "flex",
      //           alignItems: "center",
      //           justifyContent: "center",
      //           minHeight: "75vh",
      //         }}
      //       />
      //     }
      //   ></Suspense>,

      {
        path: "dashboard",
        element: <MainDashboad />,
      },
      {
        path: "role-access",
        element: <RoleAccess />,
      },
      {
        path: "access-permission",
        element: <AccessPermisson />,
      },
      {
        path: "languages",
        element: <Language />,
      },
      {
        path: "dealers-list",
        element: <DealersList />,
      },
      {
        path: "product-type",
        element: <ProductType />,
      },
      {
        path: "product-list",
        element: <ProductModalAsset />,
      },
      {
        path: "battery-types",
        element: <BatteryTypes />,
      },
      {
        path: "manufacturer",
        element: <Manufacturer />,
      },
      {
        path: "genders",
        element: <Gender />,
      },
      {
        path: "marital-status",
        element: <MaritalStatus />,
      },
      {
        path: "resident-type",
        element: <ResidentType />,
      },
      {
        path: "occupation-types",
        element: <OccupationTypes />,
      },
      {
        path: "vehicle-types",
        element: <VehicleTypes />,
      },
      {
        path: "relations",
        element: <Relations />,
      },
      {
        path: "lenders",
        element: <Lenders />,
      },
      {
        path: "lender-consent-content",
        element: <LenderConsentContent />,
      },
      {
        path: "emi-debit-consent",
        element: <EmiDebitConsent />,
      },

      {
        path: "credit-details-consent",
        element: <CreditDetailsConsent />,
      },
      {
        path: "onboarding-screen-content",
        element: <OnboardingScreenContent />,
      },
      {
        path: "borrower-list",
        element: <BorrowerList />,
      },
      {
        path: "add-borrower",
        element: <AddBorrower />,
      },
      {
        path: "view-borrower-details",
        element: <ViewBorrowerDetails />,
      },
      {
        path: "loan-application-list",
        element: <LoanApllication />,
      },
      {
        path: "view-loan-details",
        element: <ViewLoanApp />,
      },
      {
        path: "add-application",
        element: <AddLoanApplication />,
      },
      {
        path: "lsp-wallet",
        element: <Lspwallet />,
      },
      {
        path: "lsp-settings",
        element: <LspSetting />,
      },
      {
        path: "lsp-borrower-list",
        element: <Lspborrower />,
      },
      {
        path: "lsp-executive-borrower",
        element: <ExecutiveBorrower />,
      },
      {
        path: "lsp-loan-list",
        element: <LspLoan />,
      },
      {
        path: "create-dealer",
        element: <CreateDealer />,
      },
      {
        path: "edit-dealer",
        element: <EditDealerD />,
      },
      {
        path: "add-product",
        element: <AddProduct />,
      },
      {
        path: "department",
        element: <DepartmentL />,
      },
      {
        path: "user-type",
        element: <UserType />,
      },
      {
        path: "user",
        element: <User />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "excel-product-list",
        element: <ExcelProduct />,
      },
      {
        path: "dealer-authority",
        element: <DealerAuthority />,
      },
      {
        path: "add-dealer-authority",
        element: <AddDealerAuthority />,
      },
      {
        path: "view-dealer-details",
        element: <ViewDeDetails />,
      },
      {
        path: "add-lender",
        element: <AddLender />,
      },
      {
        path: "lender-authority",
        element: <LenderAuthority />,
      },
      {
        path: "lender-service-provider",
        element: <LenderServiceProvider />,
      },
      {
        path: "add-lender-service-provider",
        element: <AddLenderServiceProvider />,
      },
      {
        path: "lender-service-provider-authority",
        element: <LenderServiceProviderAuthority />,
      },
      {
        path: "lsp-executive",
        element: <LenderExacutive />,
      },
      {
        path: "add-lender-executive",
        element: <AddLenderEx />,
      },
      {
        path: "verify-user",
        element: <VarifyItsYou />,
      },
      {
        path: "change-password",
        element: <ChangePW />,
      },
      {
        path: "lsp-details",
        element: <ViewLspDet />,
      },
      {
        path: "apply-loan",
        element: <ApplyLoan />,
      },
      {
        path: "step-2",
        element: <StepTwo />,
      },
      {
        path: "verify-aadhar-pan",
        element: <VerifyAadhar />,
      },
      {
        path: "edit-general-documents",
        element: <EditGenDocuments />,
      },
      {
        path: "edit-borrower-documents",
        element: <EditBorrowerDocuments />,
      },
      {
        path: "edit-coapplicant-documents",
        element: <EditCoAppDocuments />,
      },
      {
        path: "edit-guarantor-documents",
        element: <EditGuarantorDocuments />,
      },
    ],
  },
]);
export default routes;
