import React, { useContext, useEffect } from "react";
import styles from "./BorrowerForm.module.css";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import axios from "axios";
import { useToast } from "../../../context/ToastProvder";
import { Backdrop } from "@mui/material";
import ViewImg from "./ViewImg";
import { useQueries, useQuery } from "@tanstack/react-query";
import api from "../../Common/api";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { FaMinusSquare, FaPlusSquare } from "react-icons/fa";
const DealerForm = ({ defaultValue }) => {
  const { user, token, setUser, setToken, baseURL } = useContext(AuthContext);
  const [add, setAdd] = useState(false);
  const [formdata, setFormdata] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});
  const [PFRemovedId, setPFRemovedId] = useState([]);
  const [perm, setPerm] = useState([]);
  const fetchPermissions = async () => {
    const response = await api.get("admin/get-sub-menu-permissions/48");
    return response.data;
  };
  const PermissionsResponse = useQuery({
    queryKey: ["submenuPermissions"],
    queryFn: fetchPermissions,
    onSuccess: (data) => {
      setPerm(data.data);
    },
  });
  const { data: permissionsData } = PermissionsResponse;
  const [numForms, setNumForms] = useState(
    formdata?.authorizedDirectors?.length || 1
  );
  const [authorizedDirectors, setAuthorizedDirectors] = useState([
    {
      AuthorizedDirectorsName: "",
      AuthorizedDirectorsNumber: "",
      AuthorizedDirectorsEmail: "",
    },
  ]);
  const [rowToEdit, setRowToEdit] = useState(null);
  const [err, setErr] = useState({});
  const navigate = useNavigate();
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const { showToast } = useToast();

  const [
    { data: depatmentList },
    { data: userTypeList },
    { data: dealerList },
  ] = useQueries({
    queries: [
      {
        queryKey: ["department"],
        queryFn: () =>
          api.get("get-department-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["userType"],
        queryFn: () =>
          api.get("get-user-type-screener").then((res) => res.data),
        initialData: [],
      },
      {
        queryKey: ["dealers"],
        queryFn: () => api.get("dealer-screener").then((res) => res.data),
        initialData: [],
      },
    ],
  });

  console.log("defaultValue", defaultValue);
  useEffect(() => {
    if (defaultValue) {
      setValue("name", defaultValue?.name);
      setValue("company_type", defaultValue?.company_type);
      setValue("mobile", defaultValue?.mobile);
      setValue(
        "complience_officer_mobile",
        defaultValue?.complience_officer_mobile
      );
      setValue("email", defaultValue?.email);
      setValue("lending_roi", defaultValue?.lending_roi);
      setValue("aadhar", defaultValue?.aadhar);
      setValue("alternative_number", defaultValue?.alternative_number);
      setValue("pan", defaultValue?.pan);
      setValue("pincode", defaultValue?.pincode);
      setValue("gst", defaultValue?.gst);
    }
  }, [defaultValue, setValue]);
  const onSubmit = async (data) => {
    console.log("Form data to submit:", data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("mobile", data.mobile);
    formData.append("alternative_number", data.alternative_number);
    formData.append("email", data.email);
    formData.append("pan", data.pan);
    formData.append("gst", data.gst);
    formData.append("aadhar", data.aadhar);
    formData.append("pincode", data.pincode);
    {
      !defaultValue && formData.append("password", data.password);
    }
    formData.append("company_type", data.company_type);

    {
      defaultValue && formData.append("id", defaultValue?.id);
    }

    const fileFields = [
      "address_proof_doc",
      "certificate_of_incorporation",
      "finayo_lsp_agreement",
      "gst_certificate",
      "lender_lsp_agreement",
      "moa",
      "aoa",
    ];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const oversizedFiles = [];

    for (const field of fileFields) {
      if (data[field] && data[field].length > 0) {
        const file = data[field][0];
        if (file.size > MAX_FILE_SIZE) {
          oversizedFiles.push(field);
        } else {
          formData.append(field, file);
        }
      }
    }

    if (oversizedFiles.length > 0) {
      const fieldNames = oversizedFiles.join(", ");
      showToast(
        `The files for the following fields exceed the maximum size of 5MB: ${fieldNames}.`,
        "error"
      );
      return;
    }
    try {
      const apiEndpoint = defaultValue
        ? `${baseURL}admin/update-lender-service-provider`
        : `${baseURL}admin/store-lender-service-provider`;
      const method = defaultValue ? "post" : "post";
      // const response = await axios.post(apiEndpoint, formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response = await axios({
        method: method,
        url: apiEndpoint,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.status) {
        const { status, message } = response.data;
        showToast(message, status);
        navigate("/lender-service-provider");
        console.log("Form submitted successfully:", response.data);
      } else {
        console.error("Unexpected response format:", response);
        showToast("Unexpected response format", "error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        const { message, errors } = error.response.data;
        setErr(message);
        showToast(message, "error");
      } else {
        showToast("An error occurred while submitting the form", "error");
      }
    }
  };
  const addHandle = () => {
    setAdd((val) => !val);
  };
  const handleEditRow = (rowIndex) => {
    setRowToEdit(rowIndex);
    setAdd((val) => !val);
  };
  useEffect(() => {
    if (!defaultValue) {
      const requiredFields = [
        "address_proof_doc",
        "certificate_of_incorporation",
        "finayo_lsp_agreement",
        "gst_certificate",
        "lender_lsp_agreement",
        "moa",
        "aoa",
      ];

      requiredFields.forEach((field) => {
        register(field, { required: true });
      });
    }
  }, [register, defaultValue]);
  useEffect(() => {
    if (defaultValue) {
      const fileFields = [
        "address_proof_doc",
        "certificate_of_incorporation",
        "finayo_lsp_agreement",
        "gst_certificate",
        "lender_lsp_agreement",
        "moa",
        "aoa",
      ];

      fileFields.forEach((field) => {
        if (!defaultValue[field]) {
          register(field, { required: true });
        } else {
          register(field);
        }
      });
    }
  }, [register, defaultValue]);
  return (
    <div style={{ marginBottom: "15px" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={add}
        onClick={addHandle}
      >
        <ViewImg addHandle={addHandle} zoomImgUrl={rowToEdit} />
      </Backdrop>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className={styles.searchformasc} */}
        <div className={styles.totalcheckconnn}>
          {/* <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Name<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Name"
              {...register("name", { required: true })}
              type="text"
              className={styles.inputstst}
            />
            {errors.name && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Mobile<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Mobile"
              {...register("mobile", { required: true })}
              type="number"
              className={styles.inputstst}
            />
            {errors.mobile && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Alternative Mobile Number<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Alternative Mobile Number"
              {...register("alternative_number", { required: true })}
              type="number"
              className={styles.inputstst}
            />
            {errors.alternative_number && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Email<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Email"
              {...register("email", { required: true })}
              type="email"
              className={styles.inputstst}
            />
            {errors.email && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {!defaultValue && (
            // <div className={styles.oneinputads}>

            <div className={styles.searorinp}>
              <label
                style={{
                  fontSize: "12px",
                  color: "#212529",
                  fontWeight: "bold",
                }}
              >
                Password<span className={styles.requ}>*</span>
              </label>
              <input
                // placeholder="Enter Password"
                type="password"
                className={styles.inputstst}
                {...register("password", {
                  required: true,
                  minLength: {
                    value: 8,
                    message: "Too Short",
                  },
                })}
              />
              {errors.password && (
                <span className={styles.error}>
                  {errors.password.message || "This Field is Misiing"}
                </span>
              )}
            </div>
            // </div>
          )}

          {/* </div> */}
          {/* <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              PAN No.<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter PAN Number"
              type="text"
              className={styles.inputstst}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              {...register("pan", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{10}$/,
                  message: "Please Enter Valid PAN Number",
                },
              })}
            />
            {errors.pan && (
              <span className={styles.error}>
                {errors.pan.message || "This Field is Missing."}
              </span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Aadhar Number<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Aadhar Number"
              type="text"
              className={styles.inputstst}
              {...register("aadhar", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{12}$/,
                  message: "Please Enter Aadhar Number",
                },
              })}
            />

            {errors.aadhar && (
              <span className={styles.error}>
                {errors.aadhar.message || "This Field is Missing."}
              </span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Pincode<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter Pincode"
              type="number"
              className={styles.inputstst}
              {...register("pincode", {
                required: true,
                pattern: {
                  value: /^\d{6}$/,
                  message: "Please Enter Valid Pincode",
                },
              })}
            />
            {errors.pincode && (
              <span className={styles.error}>
                {errors.pincode.message || "This Field is Missing."}
              </span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Company Type<span className={styles.requ}>*</span>
            </label>
            <select
              {...register("company_type", { required: true })}
              className={styles.inputstst}
              style={{ height: "24px" }}
            >
              <option value="">--Select Company Type--</option>
              <option value="PVT LTD">PVT LTD</option>
              <option value="LTD">LTD</option>
            </select>

            {errors.company_type && (
              <span className={styles.error}>{"This Field is Missing."}</span>
            )}
          </div>
          {/* </div> */}
          {/* <div className={styles.fourinputasc}> */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              GST<span className={styles.requ}>*</span>
            </label>
            <input
              // placeholder="Enter GST"
              type="text"
              className={styles.inputstst}
              {...register("gst", {
                required: true,
                pattern: {
                  value: /^[A-Z0-9]{15}$/,
                  message: "Please Enter Gst Number",
                },
              })}
            />

            {errors.gst && (
              <span className={styles.error}>
                {errors.gst.message || "This Field is Missing."}
              </span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              GST Certificate<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              // className={styles.inputstst}
              {...register("gst_certificate")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
              // onChange={fileChangeHandler}
              //  onChange={handleChange("balance_sheet")}
            />
            {errors.gst_certificate && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Lender LSP Agreement<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("lender_lsp_agreement")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
            />
            {errors.lender_lsp_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Finayo LSP Agreement<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("finayo_lsp_agreement")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
            />
            {errors.finayo_lsp_agreement && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* </div> */}
          {/* <div
          className={defaultValue ? styles.fourinputasc : styles.fourinputasc}
        > */}
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              MOA<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("moa")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
            />
            {errors.moa && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              AOA<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("aoa")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
            />
            {errors.aoa && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Certificate Of Incorporation
              <span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("certificate_of_incorporation")}
              // accept="image/jpeg, image/png,image/jpg"
              accept="application/pdf"
            />
            {errors.certificate_of_incorporation && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          <div className={styles.searorinp}>
            <label
              style={{
                fontSize: "12px",
                color: "#212529",
                fontWeight: "bold",
              }}
            >
              Address Proof Document<span className={styles.requ}>*</span>
            </label>
            <input
              type="file"
              className={styles.uploadbx}
              {...register("address_proof_doc")}
              accept="application/pdf"
              //accept="application/pdf"
            />
            {errors.address_proof_doc && (
              <span className={styles.error}>This Field is Missing.</span>
            )}
          </div>
          {/* </div> */}

          {perm?.includes("create") && (
            <div className={styles.buttonasc}>
              {defaultValue && (
                <button type="submit" className={styles.Subbtn}>
                  Update
                </button>
              )}
            </div>
          )}
        </div>
        <div className={styles.buttonasc} style={{ marginTop: "10px" }}>
          {!defaultValue && (
            <button type="submit" className={styles.Subbtn}>
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default DealerForm;
