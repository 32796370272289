import React, { useState } from "react";
import classes from "./Language.module.css";
import Breadcum from "../../Common/Breadcum";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import SelectModal from "./SelectModal";
const StepTwo = () => {
  const {
    register,
    unregister,
    handleSubmit,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [type, setType] = useState(null);
  const [totalPage, setTotalPage] = useState(2);
  const navigate = useNavigate();
  const handleEditRow = (typee) => {
    setType(typee);
    // navigate("/someotherpage", {
    //   state: { type },
    // });
  };
  console.log({ type });
  const location = useLocation();
  const { val } = location.state || "";
  console.log("val", val);
  return (
    <div className={classes.container} style={{ background: "#f2f2f7" }}>
      <div className={classes.mainBodyContainer}>
        <Breadcum pageName="Step 2" />
        <div className={classes["main-body"]} style={{ padding: "15px" }}>
          <div
            className={classes.tablco}
            style={{ borderBottom: "1px solid #ddd" }}
          >
            <div className={classes.totalcheckconnn}>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Preferred Language<span className={classes.requ}>*</span>
                </label>
                {/* <input
                  // placeholder="Enter Name"
                  {...register("name", { required: true })}
                  type="text"
                  className={classes.inputstst}
                /> */}
                <select
                  {...register("name", { required: true })}
                  className={classes.inputstst}
                  style={{ height: "29px" }}
                >
                  <option>--Select Language--</option>
                </select>
                {errors.name && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>

              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Vehicle Type<span className={classes.requ}>*</span>
                </label>
                {/* <input
                  // placeholder="Enter Name"
                  {...register("name", { required: true })}
                  type="text"
                  className={classes.inputstst}
                /> */}
                <select
                  {...register("hjreh", { required: true })}
                  className={classes.inputstst}
                  style={{ height: "29px" }}
                >
                  <option>--Select Vehicle Type--</option>
                </select>
                {errors.name && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
              <div className={classes.searorinp}>
                <label
                  style={{
                    fontSize: "12px",
                    color: "#212529",
                    fontWeight: "bold",
                  }}
                >
                  Dealer ID<span className={classes.requ}>*</span>
                </label>
                <input
                  {...register("ff", { required: true })}
                  type="text"
                  className={classes.inputstst}
                />

                {errors.name && (
                  <span className={classes.error}>This Field is Missing.</span>
                )}
              </div>
            </div>
            <div
              className={classes.buttonasc}
              style={{ marginTop: "10px", textAlign: "left" }}
            >
              {/* {!defaultValue && ( */}
              <button
                type="submit"
                className={classes.Subbtn}
                style={{ width: "80px", background: "#45cb85" }}
              >
                Verify
              </button>
              {/* )} */}
            </div>
            <hr style={{ border: "0.5px solid #ddd", marginTop: "10px" }} />

            <SelectModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
